/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Sage = {
		// All pages
		'common': {
			init: function() {
				// JavaScript to be fired on all pages
			},
			finalize: function() {
				// JavaScript to be fired on all pages, after page specific JS is fired

				// toggles hamburger and nav open and closed states
				var removeClass = true;
				$(".hamburger").click(function() {
					$(".hamburger").toggleClass('is-active');
					$("#sideNav").toggleClass('sideNav-open');
					$(".sideNavBody").toggleClass('sideNavBody-push');
					removeClass = false;
				});

				$(".sideNav").click(function() {
					removeClass = false;
				});

				document.addEventListener('touchstart', function(e) {
					if (removeClass && !$(e.target).hasClass('sideNav') && $('.sideNav').has($(e.target)).length === 0) {
						$(".hamburger").removeClass('is-active');
						$("#sideNav").removeClass('sideNav-open');
						$(".sideNavBody").removeClass('sideNavBody-push');
					}
					removeClass = true;
				}, false);

				$(window).scroll(function() {
					if ($(this).scrollTop() > 100) {
						$('.scrollup').fadeIn();
					} else {
						$('.scrollup').fadeOut();
					}
				});

				$('.scrollup').click(function() {
					$("html, body").animate({
						scrollTop: 0
					}, 600);
					return false;
				});

				$('select').wrap("<div class='select-wrapper' />");

				// Custom quantity input arrows
				var input = $('.qty');

				$(document).on('click','#add',function(e){
					e.preventDefault();
					input = $(this).siblings('.qty');
					if($.isNumeric(input.val())) {
						input.val(parseInt(input.val(), 10) +1);
						if($('input[name="update_cart"]').length) {
							$( 'input[name="update_cart"]' ).prop( 'disabled', false );
						}
					} else {
						input.val(1);
					}
				});

				$(document).on('click','#subtract',function(e){
					e.preventDefault();
					input = $(this).siblings('.qty');
					if($.isNumeric(input.val())) {
						if(input.val() >= 2) {
							input.val(parseInt(input.val(), 10) -1);
							if($('input[name="update_cart"]').length) {
								$( 'input[name="update_cart"]' ).prop( 'disabled', false );
							}
						}
					} else {
						input.val(1);
					}
				});

			}
		},
		// Home page
		'home': {
			init: function() {
				// JavaScript to be fired on the home page
			},
			finalize: function() {
				// JavaScript to be fired on the home page, after the init JS

				if ($('.testimonials-carousel').length) {
					$('.testimonials-carousel').slick({
						slidesToShow: 3,
						slidesToScroll: 1,
						appendArrows: '.slider-nav',
						appendDots: '.slider-nav',
						arrows: true,
						dots: true,
						responsive: [{
								breakpoint: 991,
								settings: {
									slidesToShow: 2,
									slidesToScroll: 1
								}
							},
							{
								breakpoint: 767,
								settings: {
									slidesToShow: 1,
									slidesToScroll: 1
								}
							}
						]
					});
				}

			}
		},
		'woocommerce_page': {
			init: function() {

				if ($('.product-categories').length) {

          // This segment opens the first category by default, or the current category if on a category page
          // comment out to leave all categories collapsed by default
          if( $('.product-categories').find('.current-cat').length ) {
            var currentCat = $('.product-categories').find('.current-cat');
            if( currentCat.hasClass('cat-parent')) {
              currentCat.addClass('open');
            } else {
              currentCat.parents('.cat-parent').addClass('open');
            }
          }
          // else {
          //   $('.product-categories').find('.cat-parent').first().addClass('open');
          // }

          // Copy the top level category link and prepend it to the subcategory menu
					$('.product-categories').find('.cat-parent > a').each(function() {
						var clonedItem = $(this).clone();
						var subMenu = $(this).siblings('.children');
            var catParent = $(this).parents('.cat-parent').first();
            var subCat = subMenu.find('.cat-parent');

						clonedItem.prependTo(subMenu).wrap("<li class='cat-item top-level-cat' />").text('All ' + clonedItem.text());

            if( !catParent.hasClass('open') && !subCat.hasClass('open') ) {
              catParent.addClass('transition');
              subMenu.animate({
                height: "toggle"
              }, 300, function() {
                catParent.removeClass('transition');
                catParent.removeClass('open');
              });
            } else if( !catParent.hasClass('open') && subCat.hasClass('open') ) {
              catParent.addClass('open');
            }

					});

          // Add open class to category menu when clicking top level cat link
					$('.cat-parent > a').click(function(e) {
						e.preventDefault();
						e.stopPropagation();

						var menuOpen = false;
						var catParent = $(this).parents('.cat-parent').first();

            var subMenu = catParent.find('.children').first();

						if (catParent.hasClass('open')) {
							menuOpen = true;
						}

						if( !catParent.hasClass('transition') ) {
              if (menuOpen === false) {
                catParent.addClass('transition');
                catParent.addClass('open');
                subMenu.animate({
                  height: "toggle"
                }, 300, function() {
                  catParent.removeClass('transition');
                });
  						} else {
                catParent.addClass('transition');
                catParent.removeClass('open');
                subMenu.animate({
                  height: "toggle"
                }, 300, function() {
                  catParent.removeClass('transition');
                });
              }
            }

					});

				}

			}
		},
		'single_product': {
      finalize: function() {
        if( $('.woocommerce-product-gallery__wrapper').length ) {
          var $lg = $('.woocommerce-product-gallery__wrapper').lightGallery({
            selector: '.woocommerce-product-gallery__image > a'
          });
        }
      }
    },
    'page_template_template_landing': {
      finalize: function() {
        if( $('.page-gallery').length ) {
          var $lg = $('.page-gallery').lightGallery({
            selector: '.gallery-image'
          });

          $('.open-gallery').click(function(e) {
            e.preventDefault();
            $('.page-gallery').find('.gallery-image').first().trigger("click");
          });
        }
      }
    },
    'page_template_template_store_location': {
      finalize: function() {
        if( $('.page-gallery').length ) {
          var $lg = $('.page-gallery').lightGallery({
            selector: '.gallery-image'
          });

          $('.open-gallery').click(function(e) {
            e.preventDefault();
            $('.page-gallery').find('.gallery-image').first().trigger("click");
          });
        }
      }
    },
    'has_google_map': {
      init: function() {
        document.initMap = function() {
          var map;
  				var infowindow = new google.maps.InfoWindow();
  				function initialize_gmap(bound) {
  					var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
  					var isDraggable = w > 480 ? true : false;
  					var settings = {
  						zoom: 10,
  						center: bound.getCenter(),
  						scrollwheel: false,
  						draggable: isDraggable,
  						mapTypeControl: false,
  						mapTypeControlOptions: {
  							style: google.maps.MapTypeControlStyle.DROPDOWN_MENU
  						},
  						navigationControl: false,
  						navigationControlOptions: {
  							style: google.maps.NavigationControlStyle.SMALL
  						},
  						mapTypeId: google.maps.MapTypeId.ROADMAP
  					};
  					map = new google.maps.Map(document.getElementById("map_canvas"), settings);

  					var center;
  					function calculateCenter() {
  						center = map.getCenter();
  					}
  					google.maps.event.addDomListener(map, 'idle', function() {
  						calculateCenter();
  					});
  					google.maps.event.addDomListener(window, 'resize', function() {
  						map.setCenter(center);
  					});
  				}

  				function addMarker(point_lat, point_long, address, title, showMarker) {
  					var point = new google.maps.LatLng(point_lat, point_long);
  				  var formattedAddress = address.split(' ').join('+');
  					var contentString = '<div id="gmapContent" style="text-align:left;">' +
  						'<a href="https://www.google.com/maps/dir/?api=1&destination=' + formattedAddress + '" target="_blank"><h3>' + title + '</h3></a>' +
  						'<div id="bodyContent"><p>' + address + '</p>' +
  				    '<a href="https://www.google.com/maps/dir/?api=1&destination=' + formattedAddress + '" target="_blank">' +
  				    '<i class="fa fa-external-link"></i> <strong>Get Directions</strong></a></div></div>';
  					var companyMarker = new google.maps.Marker({
  						position: point,
  						map: map,
  						title: title
  					});

  					if( showMarker === true ) {
  						infowindow.setContent(contentString);
  						infowindow.open(map, companyMarker);
  					} else {
  						google.maps.event.addListener(companyMarker, 'click', function() {
  							infowindow.setContent(contentString);
  							infowindow.open(map, this);
  						});
  					}

  				}

  				var locations = [];
  				$('.location-map').each(function() {
  					var location = [];
  					location.push(parseFloat($(this).find('.map-data').find('.lat').data('lat')));
  					location.push(parseFloat($(this).find('.map-data').find('.lng').data('lng')));
  					location.push($(this).find('.map-data').find('.address').data('address'));
  					location.push($(this).find('.map-data').find('.title').data('title'));
  					locations.push(location);
  				});

  				if ($('#map_canvas').length) {
  					var showMarker = true;
  					var offset = 0.05;
  					if( locations.length > 1 ) {
  						showMarker = false;
  						offset = 0;
  					}
  					var bound = new google.maps.LatLngBounds();
  					for (i = 0; i < locations.length; i++) {
  						bound.extend(new google.maps.LatLng(locations[i][0] + offset, locations[i][1]));
  					}
  					initialize_gmap(bound);
  					for (i = 0; i < locations.length; i++) {
  						addMarker(locations[i][0], locations[i][1], locations[i][2], locations[i][3], showMarker);
  					}
  				}
        };

        var maps_api_key = 'AIzaSyACQtK1mc33kOIPxLS63wia1d-zHaNkA2U';
        var maps_callback = 'document.initMap';
        var maps_api_url = 'https://maps.google.com/maps/api/js';
        var maps_script_tag = document.createElement('script');
        maps_script_tag.setAttribute('type', 'text/javascript');
        maps_script_tag.setAttribute('src', maps_api_url + '?key=' + maps_api_key + '&callback=' + maps_callback);
        maps_script_tag.setAttribute('defer', '');
        maps_script_tag.setAttribute('async', '');
        document.getElementsByTagName('body')[0].appendChild(maps_script_tag);
      }
    }
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function(func, funcname, args) {
			var fire;
			var namespace = Sage;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function() {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
